import React from "react";

import Day from "./Day";

interface Props {
  week: Week;
  dispatch: OrderFormDispatch;
}

export default function Week(props: Props) {
  const { name, days } = props.week;

  return (
    <div className="week">
      <div className="week-name">{name}</div>
      {days.map((day) => (
        <Day key={day.date} day={day} dispatch={props.dispatch} />
      ))}
    </div>
  );
}
