import React from "react";

interface Props {
  day: Day;
  dispatch: OrderFormDispatch;
}

export default function Day(props: Props) {
  const { day, dispatch } = props;

  const classes = ["day"];

  if (day.closed) {
    classes.push("closed");
  }

  if (day.locked) {
    classes.push("locked");
  }

  const toggle = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (!day.locked && !day.closed) {
      dispatch({ type: "toggleOrder", payload: day.date });
    }
  };

  return (
    <div className={classes.join(" ")}>
      <div className="name">{day.name}</div>
      {day.closed && <div className="closed-notice">Stengt</div>}
      {!day.closed && (
        <button
          className={"toggle" + (day.ordered ? " ordered" : "")}
          onClick={toggle}
          disabled={day.locked}>
          {day.ordered ? "Ja" : "Nei"}
        </button>
      )}
    </div>
  );
}
