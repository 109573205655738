interface Options {
  authToken?: string;
  method?: string;
}

export default function xhr<T>(
  url: string,
  options: Options,
  callback: (response: T) => void
) {
  const xhr = new XMLHttpRequest();
  xhr.open(options.method || "POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
  if (options.authToken) {
    xhr.setRequestHeader("X-CSRF-Token", options.authToken);
  }
  xhr.onload = () => {
    if (xhr.readyState == 4 && xhr.status == 200) {
      if (callback) {
        callback(JSON.parse(xhr.responseText) as T);
      }
    }
  };
  return xhr;
}
