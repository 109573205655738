import React, { useReducer, useState } from "react";

import xhr from "./xhr";
import useToastStore from "../stores/useToastStore";
import Week from "./OrderForm/Week";

interface Props {
  csrf_token: string;
  data: Week[];
  url: string;
}

function toggleOrder(state: Week[], date: string): Week[] {
  return state.map((week: Week) => {
    return {
      ...week,
      days: week.days.map((day: Day) => {
        let ordered = day.ordered;
        if (date == day.date && !day.locked && !day.closed) {
          ordered = !ordered;
        }
        return { ...day, ordered: ordered };
      })
    };
  });
}

function reducer(state: Week[], action: OrderFormAction) {
  switch (action.type) {
    case "toggleOrder":
      return toggleOrder(state, action.payload);
    default:
      return state;
  }
}

function serializeOrders(state: Week[]) {
  const orders = {};
  state.forEach((week) => {
    week.days.forEach((day) => {
      if (!day.locked && !day.closed) {
        orders[day.date] = day.ordered;
      }
    });
  });
  return orders;
}

export default function OrderForm(props: Props) {
  const [changed, setChanged] = useState(false);
  const [weeks, dispatch] = useReducer(reducer, props.data);
  const { notice } = useToastStore((state) => state);

  const save = (evt: React.MouseEvent) => {
    evt.preventDefault();

    const orderData = {
      orders: serializeOrders(weeks)
    };

    xhr(props.url, { method: "PUT", authToken: props.csrf_token }, () => {
      notice("Dine bestillinger ble lagret");
      setChanged(false);
    }).send(JSON.stringify(orderData));
  };

  const dispatchWithChange = (action: OrderFormAction) => {
    setChanged(true);
    dispatch(action);
  };

  return (
    <div className="order-form">
      <div className="calendar">
        <div className="header">
          <div className="week-name"></div>
          <div className="day">Man</div>
          <div className="day">Tir</div>
          <div className="day">Ons</div>
          <div className="day">Tor</div>
          <div className="day">Fre</div>
        </div>
        {weeks.map((week) => (
          <Week key={week.name} week={week} dispatch={dispatchWithChange} />
        ))}
      </div>
      <div className="buttons">
        <button onClick={save} disabled={!changed}>
          Lagre
        </button>
      </div>
      <div className="legend">
        <span className="reminder">
          Husk å bestill senest kl. 2300 dagen før.
        </span>
        <span className="legend-item">
          <span className="icon check"></span>
          Bestilt
        </span>
        <span className="legend-item">
          <span className="icon uncheck"></span>
          Ikke bestilt
        </span>
        <span className="legend-item">
          <span className="icon scratch"></span>
          Ingen bestilling
        </span>
      </div>
    </div>
  );
}
