// Entry point for the build script in your package.json

import Rails from "@rails/ujs";
Rails.start();

// Grid overlay
import gridOverlay from "./lib/gridOverlay";
gridOverlay();

// React
import { FC } from "react";
import * as Components from "./components";
import ReactRailsUJS from "react_ujs";
ReactRailsUJS.getConstructor = (className: string) =>
  Components[className] as FC;

// Stimulus
import { Application } from "stimulus";
import HeaderController from "./controllers/HeaderController";
const application = Application.start();
application.register("header", HeaderController);
